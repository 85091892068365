import React from 'react';

const Hero = () => {
    return (
        <div>
        
            <div className="hero">
                <div className="container">
                    <nav className="text-center">
                        <a className="logo bold" href="/"><img src="../img/logo.svg"/></a>
                    </nav>
                    <h1 className="text-center">Common Logical Fallacies</h1>
                    <p className="subheadline text-center">A handy collection of the most common logical fallacies for you to bookmark.<br/>Watch out for these in your next argument.</p>
                </div> 
                
            </div> 
        
        </div>
    )
}

export default Hero;