import React from 'react';
import LogicItem from './LogicItem';
import LogicModal from './LogicModal';
import Hero from './Hero';
import Footer from './Footer';
import data from '../data/fallacies.json';

const logicList = data.fallacies;

class LogicList extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            showModal : false,
            logic : logicList[0]
        }
    }
    openModal = (logic) => {
        this.setState(()=>({
            showModal: true,
            logic: logic
        })); 
    }
    componentWillMount = () => {
        const logicId = this.props.match.params.id;
        
        if(logicId){
            const result = logicList.find( ({ id }) => id === logicId );
            this.openModal(result)
        }
        
    }
    closeModal = () => {
        this.setState(()=>({
            showModal: false
        }));
        this.props.history.push('/');
    }
    
    fallacies = logicList.map((logic)=>{
            return (
                <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-1 fallacy" key={logic.id} >
                    <button className="logic-thumbnail-outer" onClick={() => this.openModal(logic)} >
                        <LogicItem  logic={logic} />
                    </button>
                </div>
                
            )   
    })

    render(){
        return(
            <div>
                <div className="hero-background">
                </div>
            
                <Hero />
                <div className="container-64 container-fluid">
                    <div className="row fallacies">
                        {this.fallacies}
                        <LogicModal showModal={this.state.showModal} logic={this.state.logic} closeModal={this.closeModal} />
                    </div>
                </div>
                <Footer />
            </div>
           
        ) 
        
        
    }
   
}
export default LogicList;