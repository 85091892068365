import React from 'react';
import Footer from './Footer';

const About = () =>{
   return(

    <div>
    <nav className="about-nav">
        <div className="container">
            <a className="logo" href="/">logic</a>
            <a href="./about" class="/about">About</a>
        </div>
        
    </nav>
       <div className="container about">
           
            <div className="col-sm-8 offset-sm-2 ">
                <h1>About</h1>
                <p>Fallacies are common errors in reasoning that will undermine the logic of an argument. The list of fallacies you see on this site are based of the <a href="https://www.futurelearn.com/courses/logical-and-critical-thinking">FutureLearn course on Logic and Critical Thinking</a> that I would definitely recommend everyone to complete, also its free of charge!
                </p>
                
                <p>I would recommend you to bookmark this site and use it as a reference whenever you are evaluating a political, work or peronal life related argument.
                </p>
           
                <div className="link-card">
                    <div className="link-img-background">
                        <img src="../img/my-profile.png" />
                    </div>
                    <div className="link-text">
                        <h3>Hey, I am Max!</h3>
                        <p>I am a Product Designer and creator of this site</p>
                        <button href="https://www.linkedin.com/in/maksim-karacun/">Connect</button>
                    </div>
                </div>
            </div>
       </div>
    <Footer />
    </div>
    
   )
}

export default About;