import React from 'react';
import AppRouter from '../routers/AppRouter';
import '../styles/styles.scss';
import ReactGA from 'react-ga';
import Navigation from './Navigation';


ReactGA.initialize('UA-150253183-1');
ReactGA.pageview(window.location.pathname + window.location.search);


class App extends React.Component{
    render(){
        return(
            <div>
                <AppRouter />
            </div>
           
        )
    }
}

export default App;