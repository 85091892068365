import React from 'react';
import {Link} from 'react-router-dom';

const LogicItem = (props) => {
    function shortenDescription (description) {
       if( description.length > 120){
           return (description.substring(0,120) + "...")
       }else{
           return description
       }
    }
    return(
        <Link to={props.logic.id}>
          
                <div className="logic-thumbnail-inner">
            
                    <div className="thumbnail-number">
                        <span>{props.logic.number}</span>
                    </div>
                    <img className="thumbnail-img" src={`../img/${props.logic.id}.png`} />
                    <h3 >{props.logic.name}</h3>
                    <p className="short-description">{shortenDescription(props.logic.shortDescription)}</p>
                    <img className="arrow" src={`../img/arrow.svg`} />

                </div>
        </Link>
        
    )
}
export default LogicItem;