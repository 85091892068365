import React from 'react';
import LogicList from '../components/LogicList';
import LogicModal from '../components/LogicModal';
import About from '../components/About';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

const AppRouter = () => {
    return(
        <BrowserRouter>
            <div>
                <Switch>
                    <Route path="/" component={LogicList} exact={true} />
                    <Route path="/about" component={About} />
                    <Route path="/:id" component={LogicList}  />
                </Switch>
            </div>
        </BrowserRouter>
    )
    
}
export default AppRouter;