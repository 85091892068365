import React from 'react';
import {Modal} from 'react-bootstrap';



const LogicModal = (props) => {

    function createMarkup() {
        return {__html: props.logic.longDescription};
    }
    
    return(
        <div>
            <Modal clasName="modal" show={props.showModal} onHide={props.closeModal}>
                    <div className="modal-content">
                    <Modal.Header closeButton>
                   
                     </Modal.Header>
                
                        <div className="modal-body">
                            <div className="content container">
                                <div className="row bottom-popup">
                                    <div className="center-div">
                                        <div className="top-container">
                                            <div className="top-container-img">
                                                <img src={`../img/${props.logic.id}.png`} />
                                            </div>
                                            <div className="top-container-text">
                                                <h2 className="trail-name">{props.logic.name}</h2>
                                                <p >{props.logic.shortDescription}</p>
                                            </div>
                                        </div>
                                        
                                        <div dangerouslySetInnerHTML={createMarkup()} />
                                        

                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 

            
            </Modal>

        </div>
        
    )
}


export default LogicModal;