import React from 'react';

const Footer = () => {
    return(
        <div>
            <footer className="footer">
                <div className="container-64">
                    <div className="row">
                        <div className="col-sm-6 offset-sm-3 text-center">
                            <h2>About</h2>
                            <br/>
                            <p>Feeling inspired after completing the <a href="https://www.futurelearn.com/courses/logical-and-critical-thinking" className="link">FutureLearn Logic course</a>, I've decided to create this page that would help me to recall and reference the list of the mentioned logical fallacies.</p> <p>Bookmark it and refer to it anytime you are analyzing political, professional or personal arguments.</p>
                            
                            <br/>
                            <p> &#169; 2020, Made by <a className="link" href="https://www.linkedin.com/in/maksim-karacun/" target="_blank">Max Karacuns</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer;